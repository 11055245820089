<template>
  <div
    ref="dropdownReferenceElement"
    class="c-dropdown-reference"
    :class="attributes['class']"
    @click="() => onClickDropdownReferenceElement()"
    @mouseenter="() => onMouseEnterDropdownReferenceElement()"
    @mouseleave="(event) => onMouseLeaveDropdownReferenceElement(event)"
  >
    <slot
      :close-dropdown
      :is-dropdown-open
      name="reference"
      :open-dropdown
    />
  </div>

  <Teleport
    v-if="isDropdownOpen"
    :disabled="Boolean(parentDropdownId)"
    to="#teleports"
  >
    <div
      :id
      ref="dropdownContentElement"
      class="c-dropdown-content"
      :style="dropdownContentElementStyle"
      @keydown.esc="() => closeDropdown()"
      @keydown.tab="(event) => elementsFocusTrap(event)"
      @mouseleave="(event) => onMouseLeaveDropdownContentElement(event)"
    >
      <div
        v-if="isTriggerHover"
        class="c-dropdown-overlay"
        :style="dropdownContentOverlayStyle"
      />

      <slot
        :close-dropdown
        :is-dropdown-open
        name="content"
        :open-dropdown
      />
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import {
  autoUpdate,
  flip as flipMiddleware,
  inline as inlineMiddleware,
  offset as offsetMiddleware,
  useFloating,
} from "@floating-ui/vue";

import type { OffsetOptions, Placement } from "@floating-ui/vue";

interface Properties {
  classReference?: string;
  disabledClosingByClickOutside?: boolean;
  disabledClosingByReferenceClick?: boolean;
  disabledClosingByResize?: boolean;
  disabledClosingByScroll?: boolean;
  disabledFocusingElements?: boolean;
  id: string;
  offset?: OffsetOptions;
  placement?: Placement;
  trigger?: "click" | "hover";
}

const properties = withDefaults(defineProps<Properties>(), {
  offset: 0,
  placement: "bottom-start",
  trigger: "click",
});

const emits = defineEmits<{
  close: [id: string];
  open: [id: string];
}>();

defineSlots<{
  content: [closeDropdown: () => void, isDropdownOpen: boolean, openDropdown: () => void];
  reference: [closeDropdown: () => void, isDropdownOpen: boolean, openDropdown: () => void];
}>();

const attributes = useAttrs();
const parentDropdownId = inject<null | string>("parentDropdownId", null);
const { id, offset, placement } = toRefs(properties);
const isDropdownOpen = ref(false);
const isMouseUse = ref(false);
const dropdownContentElement = ref<HTMLDivElement | null>(null);
const dropdownReferenceElement = ref<HTMLButtonElement | null>(null);
// TODO: Убрать, когда сделают useId

const { floatingStyles, isPositioned } = useFloating(dropdownReferenceElement, dropdownContentElement, {
  middleware: [flipMiddleware(), inlineMiddleware(), offsetMiddleware(offset.value)],
  open: isDropdownOpen,
  placement,
  whileElementsMounted: autoUpdate,
});

const dropdownContentElementStyle = computed(() => {
  return {
    ...floatingStyles.value,
    zIndex: "var(--z-index-dropdown)",
  };
});

const dropdownContentOverlayStyle = computed(() => {
  let dropdownOffset = 0;

  if (typeof offset.value === "object") {
    dropdownOffset = offset.value.mainAxis ?? 0;
  } else if (typeof offset.value === "number") {
    dropdownOffset = offset.value;
  } else {
    dropdownOffset = 0;
  }

  return {
    bottom: `${-dropdownOffset}px`,
    height: `calc(100% + ${dropdownOffset * 2}px`,
    left: `${-dropdownOffset}px`,
    width: `calc(100% + ${dropdownOffset * 2}px`,
  };
});

const isTriggerClick = computed(() => {
  return properties.trigger === "click";
});
const isTriggerHover = computed(() => {
  return properties.trigger === "hover";
});

const closeDropdown = () => {
  isDropdownOpen.value = false;
  isMouseUse.value = false;

  if (dropdownReferenceElement.value && !properties.disabledFocusingElements) {
    dropdownReferenceElement.value.focus();
  }

  if (!properties.disabledClosingByResize) {
    window.removeEventListener("resize", closeDropdown);
  }

  if (!properties.disabledClosingByScroll) {
    window.removeEventListener("scroll", closeDropdown);
  }

  emits("close", id.value);
};

const elementsFocusTrap = (event: KeyboardEvent) => {
  if (!properties.disabledFocusingElements && dropdownContentElement.value) {
    focusTrapRetrain(event, dropdownContentElement.value);
  }
};

const openDropdown = () => {
  if (isDropdownOpen.value) {
    return;
  }

  isDropdownOpen.value = true;

  if (!properties.disabledClosingByClickOutside) {
    onClickOutside(
      dropdownContentElement,
      () => {
        closeDropdown();
      },
      {
        ignore: [dropdownReferenceElement],
      },
    );
  }

  if (!properties.disabledClosingByResize && !parentDropdownId) {
    window.addEventListener("resize", closeDropdown);
  }

  if (!properties.disabledClosingByScroll && !parentDropdownId) {
    window.addEventListener("scroll", closeDropdown);
  }

  emits("open", id.value);
};

const toggleDropdown = () => {
  if (isDropdownOpen.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
};

const onClickDropdownReferenceElement = () => {
  if (isTriggerClick.value) {
    if (properties.disabledClosingByReferenceClick) {
      openDropdown();
    } else {
      toggleDropdown();
    }
  }
};

const onMouseEnterDropdownReferenceElement = () => {
  isMouseUse.value = true;

  if (isTriggerHover.value) {
    openDropdown();
  }
};

const onMouseLeaveDropdownReferenceElement = (event: MouseEvent) => {
  if (isTriggerHover.value) {
    const targetElement = event.relatedTarget as HTMLElement | null;

    if (!targetElement) {
      return;
    }

    if (
      !targetElement.closest(".c-dropdown-content") ||
      targetElement.closest(".c-dropdown-content")?.id !== id.value
    ) {
      closeDropdown();
    }
  }
};

const onMouseLeaveDropdownContentElement = (event: MouseEvent) => {
  if (isTriggerHover.value) {
    const targetElement = event.relatedTarget as HTMLElement | null;

    if (!targetElement) {
      return;
    }

    if (!targetElement.closest(`[id='${id.value}']`)) {
      closeDropdown();
    }
  }
};

if (!properties.disabledFocusingElements) {
  const { pressed } = useMousePressed({ target: dropdownReferenceElement });

  watch(
    () => {
      return pressed.value;
    },
    () => {
      if (pressed.value) {
        isMouseUse.value = true;
      }
    },
  );

  watch(
    () => {
      return isPositioned.value;
    },
    async () => {
      await nextTick(() => {
        if (!isMouseUse.value && isPositioned.value && dropdownContentElement.value) {
          focusTrap(dropdownContentElement.value);
        }
      });
    },
  );
}

provide("parentDropdownId", id.value);

defineExpose({
  closeDropdown,
  isDropdownOpen,
});
</script>

<style scoped>
.c-dropdown-overlay {
  position: absolute;
  z-index: -1;
}
</style>
