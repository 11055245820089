const getFocusableNodes = (element: HTMLElement) => {
  const nodes: NodeListOf<HTMLElement> = element.querySelectorAll(
    "a[href], button, details, input:not([type='hidden']), select, [tabindex]:not([tabindex='-1']), textarea",
  );

  return [...nodes].filter((focusableElement) => {
    return !focusableElement.hasAttribute("disabled") && !focusableElement.getAttribute("aria-hidden");
  });
};

const focusTrap = (element: HTMLElement) => {
  const focusableNodes = getFocusableNodes(element);

  if (focusableNodes.length > 0) {
    focusableNodes[0].focus();
  }
};

const focusTrapRetrain = (event: KeyboardEvent, element: HTMLElement) => {
  const activeElement = document.activeElement as HTMLElement;
  const focusableNodes = getFocusableNodes(element);
  const focusedItemIndex = focusableNodes.indexOf(activeElement);

  if (event.shiftKey && focusedItemIndex === 0) {
    focusableNodes.at(-1)?.focus();
    event.preventDefault();
  }

  if (!event.shiftKey && focusableNodes.length > 0 && focusedItemIndex === focusableNodes.length - 1) {
    focusableNodes[0].focus();
    event.preventDefault();
  }
};

export { focusTrap, focusTrapRetrain };
